<template>
    <el-main>
        <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-plus"
            @click="$router.push('/repair/service/addtype')">添加分类</el-button>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="服务分类：">
                <el-input size="small" v-model="form.classify_name" placeholder="请输入搜索分类"></el-input>
            </el-form-item>
            <el-form-item label="是否显示：">
                <el-select v-model="form.is_show" size="small">
                    <el-option v-for="item in showOptions" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="18px">
                <el-button type="primary" @click="getList" size="small">搜索</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column align="center" width="100px">
                <template #header>
                    <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
                </template>
                <template v-slot="{ row }">
                    <el-checkbox @change="serviceCheckChange(row)" v-model="row.checkFlag"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column prop="classify_name" label="分类名称" align="center"></el-table-column>
            <el-table-column prop="sort" label="组内权重" width="120">
                <template #header>
                    <div class="edit">
                        <span>组内权重</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input size="small" type="Number" :min="0" @change="editChange(scope.row)"
                        v-model="scope.row.sort"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="是否显示" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="0"
                        @change="editChange(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                        @click="$router.push({ path: '/repair/service/edittype', query: { info: JSON.stringify(scope.row) } })"
                        type="text" size="small">编辑</el-button>
                    <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="serviceTotal" :page="form.page" :pageNum="form.rows" @updatePageNum="updateServiceData">
            <template #batch>
                <div>
                    <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
                    <el-button size="middle" type="text" style="margin-left:10px;">批量删除</el-button>
                </div>
            </template>
        </Paging>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            showOptions: [
                {
                    value: 0,
                    label: '全部',
                },
                {
                    value: -1,
                    label: '否',
                },
                {
                    value: 1,
                    label: '是',
                },
            ],
            form: {
                page: 1,
                rows: 10,
                classify_name: '',
                is_show: 0
            },
            serviceTotal: 0
        };
    },
    created () {
        this.getList();
    },
    methods: {
        // 分页操作
        updateServiceData (val, status) {
            if (status == 0) {
                this.form.rows = val;
            } else {
                this.form.page = val;
            }
            this.getList();
        },

        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                classify_name: '',
                is_show: 0
            }
            this.getList();
        },
        getList () {
            this.$axios
                .post(this.$api.repair.service.ClassifyList, this.form)
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list
                        list.forEach(item => {
                            item.checkFlag = false
                        });
                        this.list = res.result.list
                        this.serviceTotal = res.result.total_number
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        serviceCheckChange (row) {
            console.log(row)
        },
        editChange (row) {
            this.$axios
                .post(this.$api.repair.service.ClassifyEditInfo, {
                    id: row.id,
                    sort: row.sort,
                    is_show: row.is_show
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('修改成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //删除分类
        remove (row) {
            this.$confirm('确认删除此服务分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.repair.service.delClassify, {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }

    .add-form {
        .el-form-item {

            .el-input,
            .el-select {
                width: 350px;
                margin-right: 10px;
            }
        }
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}
</style>
  